<template>
  <div>
    <div
      id="report"
    >
      <div class="flex">
        <div class="flex-2 mr-4">
          <Section class="mb-4">
            <template #title>
              <div>
                Address Information
              </div>
              <MonitoringButton v-if="addressData.isMonitoring" />
              <gl-icon
                v-else
                v-tooltip="'This report was generated by using the last 10 incoming transactions of the requested address. Add the address to the Monitoring to get a report based on the last 1000 incoming transactions'"
                class="ml-2"
                :height="24"
                name="def-report"
                :width="24"
              />
            </template>

            <template #body>
              <div
                v-if="!hasTxs && !addressDataLoading"
                class="wallet-address-wrap pa-2"
              >
                There are no transactions for this address
              </div>
              <div class="wallet-address-wrap pa-2">
                <InfoBlock
                  class="mb-4 mr-3"
                  font-size="14px"
                  label="Wallet address"
                  :loading="addressDataLoading"
                  :value="addressData.address"
                />
              </div>
              <div class="report-section__content-wrapper pa-2">
                <InfoBlock
                  class="mb-4"
                  inline-view
                  label="Balance"
                  :loading="addressDataLoading"
                  :value="addressData
                    && addressData.balance
                    && toComaSeparate(String(formatBtcAmount(addressData.balance))) || '0'"
                />
                <InfoBlock
                  class="mb-4"
                  inline-view
                  label="Total sent"
                  :loading="addressDataLoading"
                  :value="addressData
                    && addressData.amountSent
                    && toComaSeparate(String(formatBtcAmount(addressData.amountSent, true, 3)))"
                />
                <InfoBlock
                  class="mb-4"
                  inline-view
                  label="Number of txs"
                  :loading="addressDataLoading"
                  :value="addressData
                    && toComaSeparate(String(addressData.txCount)) || '0'"
                />
                <InfoBlock
                  class="mb-4"
                  inline-view
                  label="Total received"
                  :loading="addressDataLoading"
                  :value="addressData
                    && addressData.amountReceived
                    && toComaSeparate(String(formatBtcAmount(addressData.amountReceived, true, 3))) || '0'"
                />
                <InfoBlock
                  class="mb-4"
                  inline-view
                  label="Total tx sent"
                  :loading="addressDataLoading"
                  :value="addressData
                    && toComaSeparate(String(addressData.txSentCount)) || '0'"
                />
                <InfoBlock
                  class="mb-4"
                  inline-view
                  label="First seen"
                  :loading="addressDataLoading"
                  :value="addressData
                    && addressData.firstSeen
                    && formatDate(addressData.firstSeen * 1000, 'dd.MM.yyyy HH:mm')"
                />
                <InfoBlock
                  class="mb-4"
                  inline-view
                  label="Total tx received"
                  :loading="addressDataLoading"
                  :value="addressData
                    && toComaSeparate(String(addressData.txReceivedCount))"
                />
                <InfoBlock
                  class="mb-4"
                  inline-view
                  label="Last seen"
                  :loading="addressDataLoading"
                  :value="addressData
                    && addressData.lastSeen
                    && formatDate(addressData.lastSeen * 1000, 'dd.MM.yyyy HH:mm')"
                />
              </div>
            </template>
          </Section>
          <Section
            v-if="isOwnerExist(addressData) || isClusterExist(addressData)"
            class="mb-4"
          >
            <template #title>
              <div>
                Owner Information
              </div>
            </template>
            <template #body>
              <ReportSectionBody
                v-if="isOwnerExist(addressData)"
                :data-description="addressData.description"
                label-tag="Extra context tags towards the classification of the requested address"
                label-top="Owner"
                label-type="Type of the address owner"
                :owner-name="addressData.owner"
                :tag-meta-data="addressData.meta"
                :type-data="addressData.type"
              />
              <ReportSectionBody
                v-if="isClusterExist(addressData) && !isOwnerExist(addressData)"
                :data-description="addressData.clusterData.description"
                label-tag="Extra context tags towards the classification of the requested address"
                label-top="Owner"
                label-type="Type of the address owner"
                :owner-name="addressData.clusterData.owner"
                :tag-meta-data="addressData.clusterData.meta"
                :type-data="addressData.clusterData.type"
              />
            </template>
          </Section>
          <Section
            v-if="isOwnerExist(addressData) && isClusterExist(addressData)"
            class="mb-4"
          >
            <template #title>
              <div>
                Wallet Address Is Managed By
              </div>
            </template>
            <template #body>
              <ReportSectionBody
                :data-description="addressData.clusterData.description"
                label-tag="Extra context tags towards the classification of the entity"
                label-top="Entity"
                label-type="Type of entity that owns requested address"
                :owner-name="addressData.clusterData.owner"
                :tag-meta-data="addressData.clusterData.meta"
                :type-data="addressData.clusterData.type"
              />
            </template>
          </Section>
          <Section
            v-if="!isClusterExist(addressData) && !isOwnerExist(addressData)"
            class="mb-4"
          >
            <template #title>
              <div>
                Owner Information
              </div>
            </template>
            <template #body>
              <div class="ml-2">
                Not identified
              </div>
            </template>
          </Section>
        </div>
        <div class="flex-1 report-risk-score-wrapper">
          <Section>
            <template #title>
              Risk Score
            </template>

            <template #body>
              <div class="pa-3">
                <GlProgressBarChart
                  :calculation-loading="calculationLoading"
                  :has-funds-data="hasTxs"
                  :total-funds="totalFunds"
                />
                <div
                  v-if="addressRiskySources.length > 0"
                  class="mt-4 mb-4"
                >
                  <strong>{{ hasOnlyOneSource ? '100%' : formatShare(percent*100) }}</strong> of funds comes from risky sources
                </div>
                <div
                  v-else
                  class="mt-4 mb-4"
                >
                  No risky sources were found
                </div>
                <div
                  v-if="!calculationLoading && Object.keys(addressData).length > 0"
                  class="mb-5"
                >
                  <p
                    v-if="addressIsOwnerByHightRisk || hasDirectlyMixing || percent * 100 > 0 || addressAreUnidentified || hasTagMoreRiskPoint"
                    class="sidebar__analytics-label"
                  >
                    AML RISK DETECTED
                  </p>
                  <StatusBlock
                    v-if="addressIsOwnerByHightRisk"
                    class="mb-2 info-block__wrap"
                    label="Address is owned by a high risk entity"
                  />
                  <StatusBlock
                    v-if="hasDirectlyMixing"
                    class="mb-2 info-block__wrap"
                    label="Address has directly participated in mixing"
                  />
                  <StatusBlock
                    v-if="percent * 100 > 0"
                    class="mb-2 info-block__wrap"
                    :label="`Address received ${hasOnlyOneSource ? '100%' : formatShare(percent * 100)} funds from risky sources`"
                  />
                  <StatusBlock
                    v-if="addressAreUnidentified"
                    class="mb-2 info-block__wrap"
                    label="More than 75% of sources for the address are unidentified"
                  />
                  <StatusBlock
                    v-if="hasTagMoreRiskPoint"
                    class="mb-2 info-block__wrap"
                    label="Address has directly participated in high risk activities"
                  />
                </div>
                <RiskScoreDisclaimer />
                <button
                  class="gl-button gl-button--dark gl-button--padder gl-button--full mt-3"
                  :disabled="addressDataLoading || !hasTxs"
                  @click="explore(addressData.address)"
                >
                  Explore graph
                </button>
              </div>
            </template>
          </Section>
        </div>
      </div>
      <Section
        v-if="allDataSource.length > 0 && allDataSourceByOwner.length > 0 || calculationLoading"
        class="mb-4"
      >
        <template #title>
          <div>
            Sources of Funds
          </div>
        </template>

        <template #body>
          <div class="py-14px pl-2 grey-text-6-e mb-3">
            Counterparties from whom requested address has received funds
          </div>
          <div class="flex mb-4 flex-wrap pl-2 pr-2">
            <div class="flex-1">
              <PieDataList
                :data="allDataSource"
                :loading="calculationLoading"
                title="By Type"
                track-by-label="funds.type"
                track-by-label-support="funds.name"
              />
            </div>
            <div class="flex-1">
              <PieDataList
                :data="allDataSourceByOwner"
                :loading="calculationLoading"
                title="By Owner"
                track-by-label="owner"
              />
            </div>
          </div>
        </template>
      </Section>
    </div>
    <div class="report-block-wrap">
      <AddressRiskyTable
        v-if="addressRiskySources.length > 0 && !addressDataLoading"
        class="mb-5"
        :data="addressRiskySources"
        :has-only-one-source="hasOnlyOneSource"
        :loading="addressDataLoading"
      />
      <AddressUnknownTable
        v-if="addressUnknownSources.length > 0 && !addressDataLoading"
        class="mb-5"
        :data="addressUnknownSources"
        :has-only-one-source="hasOnlyOneSource"
        :loading="addressDataLoading"
      />
      <AddressKnownTable
        v-if="addressKnownSources.length > 0 && !addressDataLoading"
        :data="addressKnownSources"
        :has-only-one-source="hasOnlyOneSource"
        :loading="addressDataLoading"
      />
    </div>
  </div>
</template>

<script>
// Components
import GlIcon from "@/components/gl-icon"
import InfoBlock from '@/components/gl-info-block'
import Section from '@/components/gl-section'
import ReportSectionBody from '@/pages/report/components/ReportSectionBody'
import PieDataList from'../components/PieDataList'
import MonitoringButton from'@/pages/report/components/MonitoringButton'
import StatusBlock from "@/pages/report/components/StatusBlock";
import GlProgressBarChart from "@/components/charts/gl-progress-bar-chart";
import AddressRiskyTable from "@/pages/report/components/AddressRiskyTable";
import AddressKnownTable from "@/pages/report/components/AddressKnownTable";
import AddressUnknownTable from "@/pages/report/components/AddressUnknownTable";
import RiskScoreDisclaimer from "@/pages/report/components/RiskScoreDisclaimer";
// Utils
import {formatDate} from "@/utils/format-date";
import {toComaSeparate} from "@/utils/formatNumber";
import {formatBtcAmount} from "@/utils/format-btc-amount";
import {capitalizeFirstLetter} from "@/utils/text-formatter";
import {findColorByTypeScore, hex2rgba} from "@/utils/cytoskape-ui-rules";
import { formatFunds, ownerLabelFormatter, isOwnerExist, isClusterExist } from "@/utils/report-data-formatter";
// Vuex
import { mapState } from "vuex";

export default {
  components: {
    GlIcon,
    InfoBlock,
    Section,
    ReportSectionBody,
    StatusBlock,
    PieDataList,
    MonitoringButton,
    AddressRiskyTable,
    AddressKnownTable,
    GlProgressBarChart,
    AddressUnknownTable,
    RiskScoreDisclaimer
  },
  props: {
    hasOnlyOneSource: {
      type: Boolean,
      default: false
    },
    addressData: {
      type: Object,
      default: () => ({})
    },
    allDataSource: {
      type: Array,
      default: () => []
    },
    addressRiskySources: {
      type: Array,
      default: () => []
    },
    addressKnownSources: {
      type: Array,
      default: () => []
    },
    addressUnknownSources: {
      type: Array,
      default: () => []
    },
    allDataSourceByOwner: {
      type: Array,
      default: () => []
    },
    addressDataLoading: {
      type: Boolean,
      default: false
    },
    calculationLoading: {
      type: Boolean,
      default: false
    },
    percent: {
      type: [Number, String],
      default: 0
    },
    totalFunds: {
      type: [Number, String],
      default: 0
    },
  },
  data() {
    return {
      RISK_POINT: 55
    }
  },
  computed: {
    ...mapState('analytics', ['coinData']),
    addressIsOwnerByHightRisk() {
      return (this.addressData.tags && this.addressData.tags.find(tag => tag.score >= this.RISK_POINT))
        || (this.addressData.clusterData.tags && this.addressData.clusterData.tags.find(tag => tag.score >= this.RISK_POINT))
        || this.addressData.type && this.addressData.type.score >= this.RISK_POINT
        || (this.addressData.clusterData.type && this.addressData.clusterData.type.score >= this.RISK_POINT)
    },
    hasDirectlyMixing() {
      return (this.addressData.type && this.addressData.type.name === 'mixing')
          || (this.addressData.clusterData.type && this.addressData.clusterData.type.name === 'mixing')
          || (this.addressData.tags && this.addressData.tags.find(tag => tag.name === 'coin join participant'))
          || (this.addressData.clusterData.tags && this.addressData.clusterData.tags.find(tag => tag.name === 'coin join participant'))
    },
    hasTagMoreRiskPoint() {
      return (this.addressData.tags && this.addressData.tags.find(tag => tag.score >= this.RISK_POINT))
          || (this.addressData.clusterData.tags && this.addressData.clusterData.tags.find(tag => tag.score >= this.RISK_POINT))
    },
    addressAreUnidentified() {
      const sum = this.addressUnknownSources.reduce((acc, { share }) => acc + share, 0)

      return sum * 100 >= 75
    },
    hasTxs() {
      return Boolean(this.addressData.txCount)
    },
  },
  methods: {
    formatDate,
    toComaSeparate,
    capitalizeFirstLetter,
    formatFunds,
    findColorByTypeScore,
    hex2rgba,
    formatBtcAmount,
    ownerLabelFormatter,
    isOwnerExist,
    isClusterExist,
    explore(address) {
      const { href } = this.$router.resolve({ name: 'analytics', query: { address, type: this.coinData.key } })
      window.open(href, '_blank')
    },
    formatShare(share) {
      const formatted = (share).toFixed(2)
      return formatted === '0.00' ? '< 0.01%' : formatted + '%'
    },
  },
}
</script>

<style>
.wallet-address-wrap {
  display: grid;
  grid-template-columns: auto 2fr;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media screen and (min-width: 1450px) {
  .wrapper {
    grid-template-columns: repeat(4, 2fr);
  }
}

.info-block {
  background: #cbe7ff;
  border-radius: 5px;
  padding: 10px 5px;
}

.info-block__wrap {
  padding: 0 5px;
}
</style>
