<template>
  <div>
    <div class="report-block__header--swap py-14px pl-3">
      <div class="fs-18 bold-600 mb-1">
        Exchange Operations
      </div>
      <div class="fs-14">
        List of exchange operations (swaps) for whom requested address received funds in the requested token
      </div>
    </div>
    <div class="table-swap-wrapper">
      <div class="report-block__sub-header--swap">
        <div class="flex">
          <div class="col-2 bold-600 min-w-300 cell pl-3 py-14px">
            Contributed Info
          </div>
          <div class="col-2 bold-600 min-w-400 cell py-14px">
            Owner
          </div>
          <div class="bold-600 min-w-400 cell py-14px">
            Swap Info
          </div>
          <div class="bold-600 min-w-200 cell py-14px">
            Depth
          </div>
          <div class="bold-600 min-w-500 cell py-14px">
            Swap TX
          </div>
        </div>
      </div>
      <div>
        <div
          v-for="(item, index) in data"
          :key="index"
          class="flex row"
        >
          <div class="col-2 pr-3 py-14px pl-3 cell min-w-300">
            <div class="flex report-block__item">
              <div class="report-block__label">
                Amount
              </div>
              <div class="report-block__value">
                {{ toComaSeparate(String(item.swapData ? `${item.swapData.amount}` : formatBtcAmount(item.amount / Math.pow(10, item.decimals || coinData.decimals || 18), true, 'eth', item.symbol) || '--')) }}
              </div>
            </div>
            <div
              v-tooltip.top="`${hasOnlyOneSource ? 100 : item.share * 100}%`"
              class="flex report-block__item"
            >
              <div class="report-block__label">
                Share
              </div>
              <div class="report-block__value">
                {{ formatShare(hasOnlyOneSource ? 1 : roundShare(item.share)) }}
              </div>
            </div>
          </div>
          <div class="pr-3 py-14px cell min-w-400">
            <div class="flex report-block__item">
              <div class="report-block__label">
                Owner
              </div>
              <div class="report-block__value">
                {{ item.owner || '--' }}
              </div>
            </div>
            <div
              class="flex report-block__item"
            >
              <div class="report-block__label">
                Type
              </div>
              <div>
                <GlTag
                  v-if="item.funds && item.funds.name"
                  :score="item.funds.score"
                  :tag="capitalizeFirstLetter(item.funds.name)"
                />
                <div v-else>
                  --
                </div>
              </div>
            </div>
          </div>
          <div class="pr-3 py-14px cell min-w-400">
            <div class="flex report-block__item">
              <div class="report-block__label">
                Deposit
              </div>
              <div class="report-block__value">
                {{ item.swapData ? `${toComaSeparate(String(item.swapData.from.amountText))}` : '--' }}
              </div>
            </div>
            <div
              class="flex report-block__item"
            >
              <div class="report-block__label">
                Withdraw
              </div>
              <div class="report-block__value">
                {{ item.swapData ? `${toComaSeparate(String(item.swapData.to.amountText))}` : '--' }}
              </div>
            </div>
          </div>
          <div class="pr-3 py-14px cell min-w-200">
            <div class="flex report-block__item">
              <div class="report-block__label">
                Depth
              </div>
              <div class="report-block__value">
                {{ findMinMaxFields(item.depth) }}
              </div>
            </div>
          </div>
          <div class="py-14px pr-3 cell min-w-500">
            <div class="flex report-block__item">
              <div class="report-block__label">
                Swap TX
              </div>
              <div>
                <div
                  v-if="checkMultiple(item.tx_hash)"
                  class="report-block__value"
                >
                  Multiple
                </div>
                <div
                  v-else
                  class="link report-block__value"
                  @click="openInNewTab(item.tx_hash)"
                >
                  {{ item.tx_hash }}
                </div>
              </div>
            </div>
            <div
              class="flex report-block__item"
            >
              <div class="report-block__label">
                Direct TX
              </div>
              <div>
                <div
                  class="link report-block__value"
                  @click="openInNewTab(item.directTx)"
                >
                  {{ item.directTx }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import GlTag from '@/components/gl-tag'
// Utils
import { formatBtcAmount } from '@/utils/format-btc-amount'
import { capitalizeFirstLetter, trancateString } from "@/utils/text-formatter";
import { findMinMaxFields } from "@/utils/model";
import { roundShare } from "@/utils/sourcesFormatter";
import { checkMultiple } from "@/utils/report-data-formatter";
import { toComaSeparate } from "@/utils/formatNumber";
// Vuex
import {mapState} from "vuex";

export default {
  components: {
    GlTag
  },
  props: {
    hasOnlyOneSource: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState('analytics', ['coinType', 'coinData'])
  },
  methods: {
    checkMultiple,
    formatBtcAmount,
    findMinMaxFields,
    roundShare,
    capitalizeFirstLetter,
    trancateString,
    toComaSeparate,
    formatShare(share) {
      const formatted = (share * 100).toFixed(2)
      return formatted === '0.00' ? '< 0.01%' : formatted + '%'
    },
    openInNewTab(val) {
      const { href } = this.$router.resolve({ name: 'analytics', query: { tx: val, type: this.coinType } })
      window.open(href, '_blank')
    },
    openInNewTabAddress(val) {
      const { href } = this.$router.resolve({ name: 'analytics', query: { address: val, type: this.coinType } })
      window.open(href, '_blank')
    },
  },
}
</script>

<style>
.report-block__header--swap {
  background-color: var(--pale-blue);
}
.report-block__sub-header--swap div {
  background-color: var(--light-pale-blue);
}
.report-block__label {
  color: var(--dark-grey-6-e);
  //font-size: 14px;
  min-width: 90px;
}
.report-block__value {
  font-weight: 500;
  white-space: nowrap;
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.report-block__item {
  padding: 5px 0;
}
.report-table__swap .o-table .o-table__th:first-child {
  border-left: 4px solid var(--soft-blue) !important;
}
.report-table__swap .o-table .o-table__td:first-child {
  border-left: 4px solid var(--soft-blue) !important;
}

.table {
  display: flex;
  flex-wrap: wrap;
}

.row {
  display: flex;
  flex: 1 0 100%;
}

.cell {
  flex: 1;
  border-bottom: 1px solid var(--light-pale-blue);
}

.table-swap-wrapper {
  overflow: auto;
}
</style>
