<template>
  <div class="flex align-center">
    <gl-link
      v-if="url && isValidURL(url)"
      class="flex"
      :url="url"
    >
      <gl-icon
        :height="24"
        :name="iconName"
        :width="24"
      />{{ label || url }}
    </gl-link>
    <div
      v-else
      :class="{'ellipsis max-w-300': ellipsis}"
    >
      {{ url || '-' }}
    </div>
  </div>
</template>

<script>
// Components
import GlIcon from '@/components/gl-icon'
import GlLink from '@/components/gl-link'
// utils
import { isValidURL } from '@/utils/text-formatter'

export default {
  components: {
    GlIcon,
    GlLink
  },
  props: {
    url: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      default: "open",
    },
    label: {
      type: String,
      default: "",
    },
    ellipsis: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isValidURL,
  },
}
</script>
