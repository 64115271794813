<template>
  <button
    v-tooltip="'This wallet address is being monitored, you are able to receive fast and detailed reports as well as alerts in case of new incoming transactions, new counterparties, or AML/CTF risks identified. This report was generated by using the last 1000 incoming transactions of the requested address.'"
    aria-hidden="true"
    :class="['gl-button', 'gl-button--dark', 'gl-search-box__button', 'ml-3']"
    type="button"
  >
    Monitoring
  </button>
</template>

<script>

export default {
  name: 'MonitoringButton',
}
</script>