<template>
  <div :class="{'flex': inlineView}">
    <div
      v-if="metaData && metaData.score >= 0"
      class="min-w-200 mb-3"
      :class="{'flex-1': inlineView}"
    >
      <GlTag
        class="mr-1 mb-1"
        :score="metaData.score"
        :tag="capitalizeFirstLetter(metaData.name)"
      />
    </div>
    <div
      class="min-w-200 mb-3"
      :class="{'flex-1': inlineView}"
    >
      <div v-if="dataDescription">
        <div class="flex align-center">
          <GlLinkBlock
            :ellipsis="inlineView"
            label="Source link"
            :url="dataDescription"
          />
        </div>
      </div>
      <div v-else-if="metaData && metaData.metadata">
        <GlLinkBlock
          :ellipsis="inlineView"
          label="Source link"
          :url="metaData.metadata"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Components
import GlTag from '@/components/gl-tag'
import GlLinkBlock from '@/components/gl-link-block'

// utils
import { capitalizeFirstLetter, isValidURL } from '@/utils/text-formatter'

export default {
  name: 'TagMetaBock',
  components: {
    GlTag,
    GlLinkBlock,
  },
  props: {
    metaData: {
      type: Object,
      default: () => {},
    },
    dataDescription: {
      type: String,
      default: '',
    },
    inlineView: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    capitalizeFirstLetter,
    isValidURL,
  },
}
</script>
