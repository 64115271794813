<template>
  <a
    v-if="url"
    class="ellipsis accent-text-link"
    :href="url"
    style="max-width: 250px"
    target="_blank"
  ><slot /></a>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: '',
    },
  },
}
</script>
