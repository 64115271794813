<template>
  <GlPageWrap
    class="relative"
    title="Risk Report"
  >
    <button
      v-if="hasReportData && !isEthAddressReport"
      class="save-сsv-button"
      @click="exportReportToCsv"
    >
      Export to csv
    </button>
    <button
      v-if="hasReportData && enableGeneratePdf"
      class="save-button"
      @click="exportToPdf"
    >
      Export to pdf
    </button>
    <div class="report">
      <div class="flex mb-5">
        <GlCoinSelect dark />
        <gl-search-box
          v-model="search"
          button-text="Search"
          class="fullwidth"
          dark-clear
          :disabled="!search"
          grey
          :loading="calcLoading || addressDataLoading || txDataLoading"
          placeholder="Enter the address or tx hash"
          tagging
          @search="searchData"
        />
      </div>
      <div>
        <AddressReport
          v-if="isAddressReport"
          :address-data="addressInfo"
          :address-data-loading="addressDataLoading"
          :address-known-sources="addressKnownSources"
          :address-risky-sources="addressRiskySources"
          :address-unknown-sources="addressUnknownSources"
          :all-data-source="allDataSource"
          :all-data-source-by-owner="allDataSourceByOwner"
          :calculation-loading="calcLoading"
          :has-only-one-source="hasOnlyOneSource"
          :percent="sourcesRiskPercent"
          :total-funds="addressTotalFunds"
        />
        <TxReport
          v-else-if="isTxReport"
          :all-data-source="allDataSource"
          :all-data-source-by-owner="allDataSourceByOwner"
          :calculation-loading="calcLoading"
          :has-only-one-source="hasOnlyOneSource"
          :messages="messages"
          :percent="sourcesRiskPercent"
          :total-funds="txTotalFunds"
          :tx-data="txInfo"
          :tx-data-loading="txDataLoading"
          :tx-known-sources="txKnownSources"
          :tx-risky-sources="txRiskySources"
          :tx-unknown-sources="txUnknownSources"
        />
        <EthTxReport
          v-else-if="isEthTxReport"
          :all-data-source="allDataSource"
          :all-data-source-by-owner="allDataSourceByOwner"
          :calculation-loading="calcLoading"
          :has-only-one-source="hasOnlyOneSource"
          :messages="messages"
          :percent="sourcesRiskPercent"
          :selected-token="selectedToken"
          :swap-sources="swapSources"
          :symbol="symbol"
          :total-amount="totalAmount"
          :total-funds="txTotalFunds"
          :tx-data="txInfo"
          :tx-data-loading="txDataLoading"
          :tx-known-sources="txKnownSources"
          :tx-risky-sources="txRiskySources"
          :tx-unknown-sources="txUnknownSources"
          @set-token="getReportByToken"
        />
        <EthAddressReport
          v-else-if="isEthAddressReport"
          :address="searchValue"
          :address-data="addressInfo"
          :address-data-loading="addressDataLoading"
          :address-known-sources="addressKnownSources"
          :address-risky-sources="addressRiskySources"
          :address-unknown-sources="addressUnknownSources"
          :all-data-source="allDataSource"
          :all-data-source-by-owner="allDataSourceByOwner"
          :calculation-loading="calcLoading"
          :contract-data="contractData"
          :has-only-one-source="hasOnlyOneSource"
          :percent="sourcesRiskPercent"
          :selected-token="selectedToken"
          :swap-sources="swapSources"
          :tokens-list="tokensList"
          :tokens-loading="tokensLoading"
          :total-funds="addressTotalFunds"
          @set-token="getReportByToken"
        />
        <div
          v-else
          class="cyto-empty"
        >
          Type address or transaction hash in the search bar above to get sources of funds report.
        </div>
      </div>
    </div>
  </GlPageWrap>
</template>

<script>
// Vuex
import {mapActions, mapMutations, mapState} from "vuex";
//Components
import GlSearchBox from '@/components/gl-search-box'
import GlCoinSelect from "@/components/gl-coin-select";
import TxReport from "@/pages/report/components/TxReport";
import GlPageWrap from "@/components/layout/gl-page-wrap";
import EthTxReport from "@/pages/report/components/EthTxReport";
import AddressReport from "@/pages/report/components/AddressReport";
import EthAddressReport from "@/pages/report/components/EthAddressReport";
// Utils
import axios from 'axios'
// import {validate} from "vee-validate";
import { csvExportReport} from '@/utils/export-data'
import {formatShare, formatter} from "@/utils/sourcesFormatter";
import {findColorByTypeScore, isValidEthAddress} from "@/utils/cytoskape-ui-rules";
import {isValidEthHash, tokensSorting} from "@/utils/cytoskape-ui-rules";
import { calcSourcesRiskPercent } from "@/utils/report-data-formatter";
import config from "@/utils/appConfig";
import _ from 'lodash'
import {ethIcon} from "@/assets/static/eth-base-64-icon";
import { formatBtcAmount } from "@/utils/format-btc-amount";

export default {
  components: {
    TxReport,
    GlPageWrap,
    EthTxReport,
    GlSearchBox,
    GlCoinSelect,
    AddressReport,
    EthAddressReport
  },
  data() {
    return {
      search: '',
      addressInfo: {},
      addressData: {},
      txInfo: {},
      isEthTxReport: false,
      isAddressReport: false,
      isEthAddressReport: false,
      isTxReport: false,
      addressDataLoading: false,
      txDataLoading: false,
      calcLoading: false,
      tokensLoading: false,
      swapSources: [],
      allDataSource: [],
      allDataSourceByOwner: [],
      addressRiskySources: [],
      addressUnknownSources: [],
      addressKnownSources: [],
      txRiskySources: [],
      txUnknownSources: [],
      txKnownSources: [],
      messages: [],
      tokensList: [],
      contractData: {},
      sourcesRiskPercent: 0,
      txRiskPercent: 0,
      addressTotalFunds: 0,
      txTotalFunds: 0,
      totalAmount: 0,
      sourcesCount: 0,
      symbol: '',
      selectedToken: {
        symbol: 'ETH',
        address: ""
      },
      searchValue: ''
    }
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapState('analytics', ['coinType', 'coinData', 'currencyList']),
    hasOnlyOneSource() {
      return this.sourcesCount === 1
    },
    enableGeneratePdf() {
      return config.VUE_APP_PDF_GENERATOR
    },
    hasReportData() {
      return Object.keys(this.addressInfo).length !== 0 || Object.keys(this.txInfo).length !== 0 || this.allDataSource.length > 0
    },
    addressReport() {
      return this.isAddressReport || this.isEthAddressReport
    },
  },
  mounted() {
    const { query } = this.$route

    if (query.type) {
      this.SET_COIN_TYPE(query.type)
      const coin = this.currencyList.find(curr => curr.key === query.type)
      this.SET_COIN_DATA(coin)
      this.selectedToken.symbol = this.coinData.label
    }

    this.selectedToken.address = this.$route.query.token

    if (query.address) {
      this.search = query.address
      this.searchData(query.address)
      return
    }

    if (query.tx) {
      this.search = query.tx
      this.searchData(query.tx)
    }
  },
  methods: {
    ...mapActions({
      getTxAMLInfo: 'analytics/getTxAMLInfo',
      getTxEthData: 'analytics/getTxEthData',
      getTxEthScore: 'analytics/getTxEthScore',
      getAddressData: 'analytics/getAddressData',
      getAddressRisk2: 'analytics/getAddressRisk2',
      getTransactionInfo: 'analytics/getTransactionInfo',
      getTransactionRisk2: 'analytics/getTransactionRisk2'
    }),
    ...mapMutations('sidebar', ['SET_SHRUNK']),
    ...mapMutations('analytics', ['SET_COIN_TYPE', 'SET_COIN_DATA']),
    ...mapActions('analytics', ['getAddressTokens', 'getTxEthScore', 'getAddressEthScore', 'getEthAddressInfo', 'getAddressBalance', 'getAddressMetrics']),
    formatter,
    formatShare,
    formatBtcAmount,
    tokensSorting,
    isValidEthHash,
    csvExportReport,
    isValidEthAddress,
    findColorByTypeScore,
    calcSourcesRiskPercent,
    formatterDepthSortValue(value) {
      if (typeof value === 'number') {
        return value
      }

      if (value && value.minimum && value.maximum) {
        return value.minimum
      } else {
        return null
      }
    },
    exportToPdf() {
      axios({
        url: `${process.env.VUE_APP_DOCUMENT_GENERATOR_URL}/report/${this.addressReport ? 'address' : 'tx'}/${this.search}?userId=${this.userData.id}&currencyKey=${this.coinData.key}`,
        method: 'GET',
        responseType: 'blob',
        headers: { ['x-api-key']: this.userData.apikey }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report_${this.search}.pdf`);
        document.body.appendChild(link);
        link.click();
      });
    },
    getReportByToken(token) {
      this.selectedToken = token
      this.searchData(this.search)
    },
    loadMetrics(value) {
      this.loadingMetrics = true

      const sendData = {
        address: value,
        contract: this.selectedToken.address ? this.selectedToken.address : undefined,
        type: this.selectedToken.address ? 'tokens' : 'main'
      }

      this.getAddressMetrics(sendData).then(({ data: { totalTxs } }) => {
        this.addressInfo.txCount = totalTxs.total
        this.addressInfo.txInputsCount = totalTxs.inputs
        this.addressInfo.txOutputsCount = totalTxs.outputs
      }).catch(() => this.addressInfo.txCount = 0).finally(() => {
        this.loadingMetrics = false
      })
    },
    async searchData(value) {
      this.SET_SHRUNK(true)

      if (!this.selectedToken.address) {
        this.selectedToken = {
          symbol: this.coinData.label,
          address: ""
        }
      }

      if (this.searchValue && this.searchValue !== value) {
        this.selectedToken = {
          symbol: this.coinData.label,
          address: ""
        }
      }

      this.searchValue = value

      this.clearData()

      await this.SET_COIN_TYPE(this.coinType)

      if (this.coinData.family === 'eth' && new RegExp(this.coinData.addressRegex).test(value) && !new RegExp(this.coinData.txRegex).test(value)) {
        this.isEthAddressReport = true
        this.addressDataLoading = true
        this.calcLoading = true
        this.addressInfo = {}
        this.contractData = {}

        this.getAddressBalance({
          address: value,
          contract: this.selectedToken.address ? this.selectedToken.address : undefined })
            .then(({ data }) => {
              this.addressInfo.balance = data?.balance?.amount || 0
            })

        this.loadMetrics(value)
        this.tokensLoading = true

        this.getAddressTokens(value).then(({ data, success }) => {
          if (!success) {
            this.$toasted.global.error({message: `${data.message}`})
          }

          this.tokensList = this.tokensSorting(data)
          this.tokensList.unshift({ address: '', symbol: this.coinData.label, icon: ethIcon })

          if (this.$route.query.token) {
            this.selectedToken = this.tokensList.find(el => el.address === this.$route.query.token)
            if (!this.selectedToken) {
              this.selectedToken = { address: '', symbol: this.coinData.label }
              this.$router.push({ name: 'report', query: { type: this.coinType, address: value, token: this.selectedToken.address } })
            }

            let index = this.tokensList.findIndex(el => el.address === this.selectedToken.address);

            if (index !== -1) {
              this.tokensList.splice(index, 1);
              this.tokensList.unshift(this.selectedToken);
            }
          }

          this.getEthAddressInfo(value).then(({ data, success }) => {
            if (success) {
              this.addressInfo = {
                ...this.addressInfo,
                ...data,
                assumedMeta: this.formattingScoringList(data) || []
              }

              this.contractData = {
                ...data,
                type: data.addressType
              }
              this.addressDataLoading = false
            }
          }).catch(() => {
            this.addressDataLoading = false
          }).finally(() => {
            this.addressDataLoading = false
          })

          this.getAddressEthScore({ address: this.search, token: this.selectedToken.address, tokenTicker: this.selectedToken.symbol })
            .then(({ data: { sources, totalFunds, message, currencies }, success}) => {
              if (!success) this.calcLoading = false

              if (message) {
                this.$toasted.global.error({message: `${message}`})
              }
            this.addressTotalFunds = totalFunds || 0
              try {
                sources = sources.map((source) => {
                  const localCurrency = currencies[source.currency] || {}
                  return {
                    ...source,
                    currencyData: localCurrency,
                    formattedAmount: this.formatBtcAmount(source.amount / Math.pow(10, localCurrency.decimals || this.coinData.decimals || 18), true,  this.coinData.key, localCurrency.currency),
                    depthSortValue: this.formatterDepthSortValue(source.depth)
                  }
                })
              } catch (e) {
                console.log(e)
              }

              console.log(sources)

            this.sourcesCount = sources.length

            this.addressRiskySources = sources.filter(source => source.listType === 'Risky sources')
            this.addressUnknownSources = sources.filter(source => source.listType === 'Unknown sources')
            this.addressKnownSources = sources.filter(source => source.listType === 'Known sources')
            this.swapSources = sources.filter(source => source.listType === 'Swap')

              // let hashMapSourceSwapData = sources.filter(source => source.inputAmounts && source?.inputAmounts?.find(item => item.currency)).map((el) => {
              //   const currencyObject = el?.inputAmounts?.find(item => item.currency);
              //
              //   if (currencyObject && currencies[currencyObject.currency]) {
              //     return {
              //       ...el,
              //       currency: currencies[currencyObject.currency]
              //         ? {
              //         ...currencies[currencyObject.currency],
              //           amount: currencyObject.amount
              //         }
              //         : null
              //     };
              //   } else {
              //     // Handle the case when currencyObject is undefined
              //     console.error("Currency not found for element:", el);
              //     return {
              //       ...el,
              //       currency: null
              //     };
              //   }
              // });

              this.swapSources = this.swapSources.map(t => {
                const currencyInputObject = t?.inputAmounts?.find(item => item.currency) || t?.inputAmounts[0];
                const currencyOutputObject = t?.outputAmounts?.find(item => item.currency === this.selectedToken.address) || t?.outputAmounts[0]
                  // ? t?.outputAmounts?.find(item => item.currency === this.selectedToken.address)
                  // : t?.outputAmounts?.find(item => item.currency) || t?.outputAmounts?.find(item => item.amount);

                const currencyInput = currencyInputObject && currencies[currencyInputObject.currency]
                  ? {
                    ...currencies[currencyInputObject.currency],
                    amount: currencyInputObject.amount
                  }
                  : {...currencyInputObject} || null

                const currencyOutput = currencyOutputObject && currencyOutputObject.currency && currencies[currencyOutputObject.currency]
                  ? {
                    ...currencies[currencyOutputObject.currency],
                    amount: currencyOutputObject.amount
                  }
                  : { ...currencyOutputObject } || null

                const currencyMain = t && t.currency && currencies[t.currency]
                  ? {
                    ...currencies[t.currency],
                    amount: t.amount
                  }
                  : { ...t } || null

                // console.log(currencyMain, 423423)
                // console.log(currencyOutput, 'currencyOutput')
                //
                // console.log(formatBtcAmount(currencyMain?.amount / Math.pow(10, currencyMain.decimals || this.coinData.decimals || 18), true, this.coinData.family, currencyMain.currency || null), 'main amount')
                // console.log(formatBtcAmount(currencyInput.amount / Math.pow(10, currencyInput.decimals || 18), true, this.coinData.family, currencyInput.currency), 'from')
                // console.log(formatBtcAmount(currencyOutput.amount / Math.pow(10, currencyOutput.decimals || 18), true, this.coinData.family, currencyOutput.currency), 'to')

                return {
                  ...t,
                  swapData: {
                    amount: formatBtcAmount(currencyMain?.amount / Math.pow(10, currencyMain.decimals || this.coinData.decimals || 18), true, this.coinData.family, currencyMain.currency || null) || '',
                    from: {
                      amountText: currencyInput ? formatBtcAmount(currencyInput.amount / Math.pow(10, currencyInput.decimals || this.coinData.decimals || 18), true, this.coinData.family, currencyInput.currency) : '',
                      symbol: currencyInput.currency
                    },
                    to: {
                      amountText: currencyOutput ? formatBtcAmount(currencyOutput.amount / Math.pow(10, currencyOutput.decimals || this.coinData.decimals || 18), true, this.coinData.family, currencyOutput.currency) : '',
                      symbol: currencyOutput.currency
                    }
                  }
                }
              })

              console.log(this.swapSources)


            const groupedSourcesByType = formatter(sources, 'funds.type')

            this.sourcesRiskPercent = 0

            sources.forEach(item => {
              if (item.funds.score >= 55) {
                this.sourcesRiskPercent += item.share
              }
            })

            this.allDataSource = groupedSourcesByType.map(item => ({
              ...item,
              funds: {
                ...item.funds,
                default: Boolean(item.funds.default)
              },
              key: item.funds.type,
              tooltip: `${item.funds.type} ${formatShare(item.share)}`,
              pieValue: item.share,
              value: item.share,
              itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
            })).sort((a, b) => ((a.share < b.share)) ? 1 : -1)

            const groupedSourcesByOwner = formatter(sources, 'owner')

            this.allDataSourceByOwner = groupedSourcesByOwner.map(item => ({
              ...item,
              funds: {
                ...item.funds,
                default: Boolean(item.funds.default)
              },
              key: item.owner,
              tooltip: `${item.owner} ${formatShare(item.share)}`,
              pieValue: item.share,
              value: item.share,
              itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
            })).sort((a, b) => ((a.share < b.share)) ? 1 : -1)
          }).catch(() => {
            this.calcLoading = false
            this.tokensLoading = false
          }).finally(() => {
            this.calcLoading = false
          })
        }).catch(({ response: { data } }) => {
          if (data.data.message) {
            this.$toasted.global.error({message: `${data.data.message}`})
          }
          this.addressDataLoading = false
          this.calcLoading = false
          this.tokensLoading = false
        }).finally(() => {
            this.tokensLoading = false
        })

        await this.$router.push({ name: 'report', query: { type: this.coinType, address: value, token: this.selectedToken.address } })

        return
      }

      if (this.$can('use', this.coinData.family)  && this.coinData.family === 'eth' && new RegExp(this.coinData.txRegex).test(value)) {
        this.isEthTxReport = true
        this.txDataLoading = true
        this.calcLoading = true

        const a = await this.getTxEthData({ tx: value }).then(({ data, success }) => {

          if (!success) this.calcLoading = false

          if (data.message) {
            this.txInfo = {}
            this.totalAmount = {}
            this.$toasted.global.error({message: `${data.message}`})
          }

          const localTxData = data?.txs[0] || {}

          const contractsData = Array.isArray(data.txs) ? _.uniqBy(data.txs.filter(tx => tx.contractData && tx.contractData.name).map(tx => tx.contractData), 'address') : []

          this.txInfo = {
            ...localTxData,
            tx_hash: this.search,
            contractsListData: tokensSorting(contractsData)
          }
        }).finally(() => {
          this.txDataLoading = false
        })
        const c = this.getTxEthScore({ tx: this.search, token: this.selectedToken.address, tokenTicker: this.selectedToken.symbol })
          .then(({ data: { sources, totalFunds, totalAmount, symbol, message, currencies }, success}) => {
            if (!success) this.calcLoading = false

            if (message) {
              this.$toasted.global.error({message: `${message}`})
            }

            this.totalAmount = {
              amount: totalAmount,
              ...this.selectedToken,
            }
          this.symbol = symbol
          this.txTotalFunds = totalFunds
          sources = sources.map((source) => {
            const localCurrency = currencies[source.currency] || null
            return {
              ...source,
              currencyData: localCurrency,
              formattedAmount: localCurrency ? this.formatBtcAmount(source.amount / Math.pow(10, localCurrency.decimals || this.coinData.decimals || 18), true,  this.coinData.family, localCurrency.currency) : undefined,
              depthSortValue: this.formatterDepthSortValue(source.depth)
            }
          })

          this.sourcesCount = sources.length

          this.txRiskySources = sources.filter(source => source.listType === 'Risky sources')
          this.txUnknownSources = sources.filter(source => source.listType === 'Unknown sources')
          this.txKnownSources = sources.filter(source => source.listType === 'Known sources')
          this.swapSources = sources.filter(source => source.listType === 'Swap')
            this.swapSources = this.swapSources.map(t => {
              const currencyInputObject = t?.inputAmounts?.find(item => item.currency) || t?.inputAmounts[0];
              const currencyOutputObject = t?.outputAmounts?.find(item => item.currency === this.selectedToken.address) || t?.outputAmounts[0]
              const currencyInput = currencyInputObject && currencies[currencyInputObject.currency]
                ? {
                  ...currencies[currencyInputObject.currency],
                  amount: currencyInputObject.amount
                }
                : {...currencyInputObject} || null

              const currencyOutput = currencyOutputObject && currencyOutputObject.currency && currencies[currencyOutputObject.currency]
                ? {
                  ...currencies[currencyOutputObject.currency],
                  amount: currencyOutputObject.amount
                }
                : { ...currencyOutputObject } || null

              const currencyMain = t && t.currency && currencies[t.currency]
                ? {
                  ...currencies[t.currency],
                  amount: t.amount
                }
                : { ...t } || null

              return {
                ...t,
                swapData: {
                  amount: formatBtcAmount(currencyMain?.amount / Math.pow(10, currencyMain.decimals || this.coinData.decimals || 18), true, this.coinData.family, currencyMain.currency || null) || '',
                  from: {
                    amountText: currencyInput ? formatBtcAmount(currencyInput.amount / Math.pow(10, currencyInput.decimals || this.coinData.decimals || 18), true, this.coinData.family, currencyInput.currency) : '',
                    symbol: currencyInput.currency
                  },
                  to: {
                    amountText: currencyOutput ? formatBtcAmount(currencyOutput.amount / Math.pow(10, currencyOutput.decimals || this.coinData.decimals || 18), true, this.coinData.family, currencyOutput.currency) : '',
                    symbol: currencyOutput.currency
                  }
                }
              }
            })

          const groupedSourcesByType = formatter(sources, 'funds.type')

          this.sourcesRiskPercent = 0

          this.sourcesRiskPercent = this.calcSourcesRiskPercent(sources)

          this.allDataSource = groupedSourcesByType.map(item => ({
            ...item,
            funds: {
              ...item.funds,
              default: Boolean(item.funds.default)
            },
            key: item.funds.type,
            tooltip: `${item.funds.type} ${formatShare(item.share)}`,
            pieValue: item.share,
            value: item.share,
            itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
          }))

          const groupedSourcesByOwner = formatter(sources, 'owner')

          this.allDataSourceByOwner = groupedSourcesByOwner.map(item => ({
            ...item,
            funds: {
              ...item.funds,
              default: Boolean(item.funds.default)
            },
            key: item.owner,
            tooltip: `${item.owner} ${formatShare(item.share)}`,
            pieValue: item.share,
            value: item.share,
            itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
          }))
        }).finally(() => {
          this.calcLoading = false
        })

        await Promise.allSettled([a, c]).finally(() => {
          this.calcLoading = false
        })

        if (!this.selectedToken.address && !this.allDataSource.length && this.txInfo.contractsListData.length) {
          this.selectedToken = this.txInfo.contractsListData[0]
          this.searchData(this.search)
        }

        await this.$router.push({ name: 'report', query: { tx: value, type: this.coinType, token: this.selectedToken.address } })
        return
      }

      if (this.$can('use', this.coinData.family) && new RegExp(this.coinData.txRegex).test(value)) {
        this.isTxReport = true
        this.txDataLoading = true
        this.calcLoading = true

        const a = this.getTransactionInfo({ txHash: value }).then(({ data }) => {
          if (data.message) {
            this.$toasted.global.error({message: `${data.message}`})
          }
          this.txInfo = data
          this.txDataLoading = false
        })
        const b = this.getTxAMLInfo({ tx_hash: value })
          .then(({ data }) => {
            this.messages = data.messages
          }).catch(({response: {data}}) => {
            if (data.statusCode && data.statusCode !== 500) {
              this.$toasted.global.error({message: `${data.message}`})
            }
          })
        const c = this.getTransactionRisk2(value).then(({ data: { sources, totalFunds } }) => {
          this.txTotalFunds = totalFunds
          sources = sources.map((source) => ({
            ...source,
            depthSortValue: this.formatterDepthSortValue(source.depth)
          }))

          this.sourcesCount = sources.length

          this.txRiskySources = sources.filter(source => source.listType === 'Risky sources')
          this.txUnknownSources = sources.filter(source => source.listType === 'Unknown sources')
          this.txKnownSources = sources.filter(source => source.listType === 'Known sources')

          const groupedSourcesByType = formatter(sources, 'funds.type')

          this.sourcesRiskPercent = 0

          this.sourcesRiskPercent = this.calcSourcesRiskPercent(sources)

          this.allDataSource = groupedSourcesByType.map(item => ({
            ...item,
            funds: {
              ...item.funds,
              default: Boolean(item.funds.default)
            },
            key: item.funds.type,
            tooltip: `${item.funds.type} ${formatShare(item.share)}`,
            pieValue: item.share,
            value: item.share,
            itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
          }))

          const groupedSourcesByOwner = formatter(sources, 'owner')

          this.allDataSourceByOwner = groupedSourcesByOwner.map(item => ({
            ...item,
            funds: {
              ...item.funds,
              default: Boolean(item.funds.default)
            },
            key: item.owner,
            tooltip: `${item.owner} ${formatShare(item.share)}`,
            pieValue: item.share,
            value: item.share,
            itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
          }))
          this.calcLoading = false
        })

        await Promise.allSettled([a, b, c]).finally(() => {
          this.calcLoading = false
        })

        await this.$router.push({ name: 'report', query: { tx: value, type: this.coinType } })
        return
      }

      if (this.$can('use', this.coinData.family) && new RegExp(this.coinData.addressRegex).test(value) ) {
        this.isAddressReport = true
        this.addressDataLoading = true
        this.calcLoading = true
        this.getAddressData(value).then(({data, success}) => {
          if (!success) {
            this.$toasted.global.error({message: `${data.message}`})
            return
          }
          this.addressInfo = {
            ...data,
            assumedMeta: this.formattingScoringList(data) || []
          }
        })
        .finally(() => {
          this.addressDataLoading = false
        }),
        this.getAddressRisk2(value).then(({ data: { sources, totalFunds } }) => {
          this.addressTotalFunds = totalFunds
          sources = sources.map((source) => ({
            ...source,
            depthSortValue: this.formatterDepthSortValue(source.depth)
          }))

          this.sourcesCount = sources.length

          this.addressRiskySources = sources.filter(source => source.listType === 'Risky sources')
          this.addressUnknownSources = sources.filter(source => source.listType === 'Unknown sources')
          this.addressKnownSources = sources.filter(source => source.listType === 'Known sources')

          const groupedSourcesByType = formatter(sources, 'funds.type')

          this.sourcesRiskPercent = 0

          this.sourcesRiskPercent = this.calcSourcesRiskPercent(sources)

          this.allDataSource = groupedSourcesByType.map(item => ({
            ...item,
            funds: {
              ...item.funds,
              default: Boolean(item.funds.default)
            },
            key: item.funds.type,
            tooltip: `${item.funds.type} ${formatShare(item.share)}`,
            pieValue: item.share,
            value: item.share,
            itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
          })).sort((a, b) => ((a.share < b.share)) ? 1 : -1)

          const groupedSourcesByOwner = formatter(sources, 'owner')

          this.allDataSourceByOwner = groupedSourcesByOwner.map(item => ({
            ...item,
            funds: {
              ...item.funds,
              default: Boolean(item.funds.default)
            },
            key: item.owner,
            tooltip: `${item.owner} ${formatShare(item.share)}`,
            pieValue: item.share,
            value: item.share,
            itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
          })).sort((a, b) => ((a.share < b.share)) ? 1 : -1)
        }).finally(() => {
          this.calcLoading = false
        })

        await this.$router.replace({ name: 'report', query: { address: value, type: this.coinType } }).catch((err) => err)
        return
      }
      this.$toasted.global.error({ message: 'Search value is not valid'})
    },
    formattingScoringList(data) {
      let SCORING_LIST = []

      if (data.tags) {
        SCORING_LIST = [...SCORING_LIST, ...data.tags]
      }

      if (data.clusterData && data.clusterData.tags) {
        SCORING_LIST = [...SCORING_LIST, ...data.clusterData.tags]
      }

      if (data.type) {
        SCORING_LIST = [...SCORING_LIST, data.type]
      }

      if (data.clusterData && data.clusterData.type) {
        SCORING_LIST = [...SCORING_LIST, data.clusterData.type]
      }

      SCORING_LIST = SCORING_LIST.filter((v,i,a)=>a.findIndex(t=>(t.name===v.name))===i)

      SCORING_LIST.sort((a, b) => ((a.score < b.score)) ? 1 : -1)

      return SCORING_LIST
    },
    clearData() {
      this.isAddressReport = false
      this.isTxReport = false
      this.isEthTxReport = false
      this.isEthAddressReport = false

      this.addressData = null
      this.addressInfo = {}

      this.swapSources = []
      this.allDataSource = []
      this.allDataSourceByOwner = []
      this.addressRiskySources = []
      this.addressUnknownSources = []
      this.addressKnownSources = []
      this.txRiskySources = []
      this.txUnknownSources = []
      this.txKnownSources = []

      this.sourcesRiskPercent = 0
      this.txRiskPercent = 0
      this.addressTotalFunds = 0
      this.txTotalFunds = 0
      this.sourcesCount = 0
    },

    exportReportToCsv() {
      if (this.isAddressReport) {
        this.csvExportReport(
            this.addressRiskySources,
            this.addressKnownSources,
            this.addressUnknownSources,
            this.addressInfo,
            this.addressTotalFunds,
            'address',
            this.sourcesRiskPercent,
            `${this.search}_report`)
      } else if (this.isTxReport) {
        this.csvExportReport(
            this.txRiskySources,
            this.txKnownSources,
            this.txUnknownSources,
            this.txInfo,
            this.txTotalFunds,
            'tx_hash',
            this.txRiskPercent,
            `${this.search}_report`)
      }
    },
  }
}
</script>

<style>
.save-сsv-button {
  position: fixed;
  bottom: 50px;
  right: 180px;
  background-color: var(--accent-reflex-blue);
  border: none;
  border-radius: 3px;
  color: var(--white);
  cursor: pointer;
  font-weight: bold;
  line-height: 1.29;
  min-width: 100px;
  padding: 6px;
  text-align: center;
  text-transform: uppercase;
  z-index: 10;
}

.report {
  background: var(--white);
  border-radius: 3px;
  padding: 24px;
}

.report-block__header {
  align-items: center;
  display: flex;
  font-size: 18px;
  padding: 14px;
  font-weight: 600;
  border: 1px solid #fff;
  border-bottom: 0;
}

.report-block__header--risk {
  background: var(--risk);
}

.report-block__header--known {
  background: var(--known);
}

.report-block__header--unknown {
  background: var(--unknown);
}

.circle-progress svg {
  width: 100%;
}

/*.circle-progress svg path {*/
/*  stroke-width: px;*/
/*}*/

.a-circle-progress-wrapper .circle-progress {
  flex-direction: column;
}

.a-circle-progress-wrapper .circle-progress .progress-content .inner-default-percentage {
 align-items: flex-end;
}

.save-button {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background-color: var(--accent-reflex-blue);
  border: none;
  border-radius: 3px;
  color: var(--white);
  cursor: pointer;
  font-weight: bold;
  line-height: 1.29;
  min-width: 100px;
  padding: 6px;
  text-align: center;
  text-transform: uppercase;
  z-index: 10;
}

.report-block-wrap {
  padding: 5px;
  border: 1px solid var(--pale-grey);
  border-radius: 5px;
}

.report-block__header--sof {
  background: rgba(240, 242, 249, 1);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin: 12px 0;
}
</style>
