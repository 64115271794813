<template>
  <gl-modal
    v-bind="$attrs"
    full-buttons
    :loading="loading"
    submit-title="Select"
    title="Choose a Token"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <vSelect
      v-model="token"
      class="mb-4"
      :clearable="false"
      label="symbol"
      :options="tokensList"
    >
      <template v-slot:option="option">
        <GlImg
          v-if="option.icon"
          class="mr-1"
          :src-val="option.icon"
        />
        {{ option.symbol }}
      </template>
    </vSelect>
  </gl-modal>
</template>

<script>
// Components
import vSelect from "vue-select";
import GlModal from '@/components/gl-modal'

// Vuex
import { mapActions } from "vuex";
import GlImg from "@/components/gl-img.vue";
import {ethIcon} from "@/assets/static/eth-base-64-icon";

// Utils
import { tokensSorting } from "@/utils/cytoskape-ui-rules";

export default {
  components: {
    GlImg,
    GlModal,
    vSelect,
  },
  inheritAttrs: false,
  props: {
    address: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      token: {
        address: '',
        symbol: 'ETH',
        icon: ethIcon
      },
      loading: false,
      tokensList: [],
    }
  },
  mounted() {
    if (this.address) {
      this.loading = true
      this.getAddressTokens(this.address).then(({ data }) => {
        this.tokensList = this.tokensSorting(data)
      }).finally(() => {
        this.loading = false
      })
    }
  },
  methods: {
    ...mapActions('analytics', ['getAddressTokens']),
    tokensSorting,
    onSubmit() {
      this.$emit('select', this.token)
      this.$emit('close')
    },
  },
}
</script>
