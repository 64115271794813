<template>
  <div>
    <div
      v-if="!tokensLoading"
      class="flex"
    >
      <div class="flex flex-items-center mb-4">
        <div class="report-title mr-3">
          Requested Token report
        </div>
        <ChooseTokenTreeSelect
          :address="address"
          :coin-data="coinData"
          :selected-token="selectedToken"
          @select="selectToken"
        />
      </div>
    </div>
    <div
      id="report"
    >
      <div class="flex">
        <div class="flex-2 mr-4">
          <component
            :is="sectionsComponent"
            :address="address"
            :address-data="addressData"
            :address-data-loading="addressDataLoading"
            class="mb-4"
            :contract-data="contractData"
            :has-txs="hasTxs"
            :selected-token="selectedToken"
          />
        </div>
        <div class="flex-1 report-risk-score-wrapper">
          <Section>
            <template #title>
              Risk Score
            </template>

            <template #body>
              <div class="pa-3">
                <GlProgressBarChart
                  :calculation-loading="calculationLoading"
                  :has-funds-data="hasTxs"
                  :total-funds="totalFunds"
                />
                <div
                  v-if="addressRiskySources.length > 0"
                  class="mt-4 mb-4"
                >
                  <strong>{{ formatShare(percent*100) }}</strong> of funds comes from risky sources
                </div>
                <div
                  v-else
                  class="mt-4 mb-4"
                >
                  No risky sources were found
                </div>
                <RiskScoreDisclaimer />
                <button
                  class="gl-button gl-button--dark gl-button--padder gl-button--full mt-3"
                  :disabled="addressDataLoading"
                  @click="explore(addressData.tx_hash)"
                >
                  Explore graph
                </button>
              </div>
            </template>
          </Section>
        </div>
      </div>
      <Section
        v-if="allDataSource.length > 0 && allDataSourceByOwner.length > 0 || calculationLoading"
        class="mb-4"
      >
        <template #title>
          <div>
            Sources of Funds
          </div>
        </template>

        <template #body>
          <div class="py-14px pl-2 grey-text-6-e mb-3">
            Counterparties from whom requested address has received funds
          </div>
          <div class="flex mb-4 flex-wrap pl-2 pr-2">
            <div class="flex-1">
              <PieDataList
                :data="allDataSource"
                :loading="calculationLoading"
                title="By Type"
                track-by-label="funds.type"
                track-by-label-support="funds.name"
              />
            </div>
            <div class="flex-1">
              <PieDataList
                :data="allDataSourceByOwner"
                :loading="calculationLoading"
                title="By Owner"
                track-by-label="owner"
              />
            </div>
          </div>
        </template>
      </Section>
      <div class="report-block-wrap">
        <SwapTable
          v-if="swapSources.length > 0 && !addressDataLoading"
          class="mb-5"
          :data="swapSources"
          :has-only-one-source="hasOnlyOneSource"
          :loading="addressDataLoading"
        />
        <AddressRiskyTable
          v-if="addressRiskySources.length > 0 && !addressDataLoading"
          class="mb-5"
          :data="addressRiskySources"
          :has-only-one-source="hasOnlyOneSource"
          :loading="addressDataLoading"
        />
        <AddressUnknownTable
          v-if="addressUnknownSources.length > 0 && !addressDataLoading"
          class="mb-5"
          :data="addressUnknownSources"
          :has-only-one-source="hasOnlyOneSource"
          :loading="addressDataLoading"
        />
        <AddressKnownTable
          v-if="addressKnownSources.length > 0 && !addressDataLoading"
          :data="addressKnownSources"
          :has-only-one-source="hasOnlyOneSource"
          :loading="addressDataLoading"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Components
import GlImg from "@/components/gl-img.vue";
import GlTag from "@/components/gl-tag";
import GlButton from "@/components/gl-button";
import InfoBlock from '@/components/gl-info-block';
import Section from '@/components/gl-section'
import ChooseTokenTreeSelect from '@/pages/report/components/ChooseTokenTreeSelect'
import PieDataList from'../components/PieDataList';
import SwapTable from "@/pages/report/components/SwapTable";
import chooseTokenModal from "@/pages/report/modals/chooseTokenModal";
import GlProgressBarChart from "@/components/charts/gl-progress-bar-chart";
import AddressRiskyTable from "@/pages/report/components/AddressRiskyTable";
import AddressKnownTable from "@/pages/report/components/AddressKnownTable";
import AddressUnknownTable from "@/pages/report/components/AddressUnknownTable";
import RiskScoreDisclaimer from "@/pages/report/components/RiskScoreDisclaimer";
// Utils
import {formatDate} from "@/utils/format-date";
import {toComaSeparate} from "@/utils/formatNumber";
import {formatBtcAmount} from "@/utils/format-btc-amount";
import {capitalizeFirstLetter, trancateString} from "@/utils/text-formatter";
import {findColorByTypeScore, hex2rgba} from "@/utils/cytoskape-ui-rules";
import { formatFunds } from "@/utils/report-data-formatter";
import { ownerLabelFormatter } from "@/utils/report-data-formatter";
// Vuex
import { mapState } from "vuex";

const EthSectionAddressInfo = () => import("@/pages/report/components/eth-section-body/EthSectionAddressInfo");
const EthSectionContractInfo = () => import("@/pages/report/components/eth-section-body/EthSectionContractInfo");
const EthSectionTokenContractInfo = () => import("@/pages/report/components/eth-section-body/EthSectionTokenContractInfo");

export default {
  components: {
    GlImg,
    GlTag,
    GlButton,
    InfoBlock,
    SwapTable,
    Section,
    PieDataList,
    chooseTokenModal,
    GlProgressBarChart,
    AddressRiskyTable,
    AddressKnownTable,
    AddressUnknownTable,
    RiskScoreDisclaimer,
    ChooseTokenTreeSelect
  },
  props: {
    hasOnlyOneSource: {
      type: Boolean,
      default: false
    },
    tokensLoading: {
      type: Boolean,
      default: false
    },
    contractData: {
      type: Object,
      default: () => ({})
    },
    addressData: {
      type: Object,
      default: () => ({})
    },
    swapSources: {
      type: Array,
      default: () => []
    },
    allDataSource: {
      type: Array,
      default: () => []
    },
    allDataSourceByOwner: {
      type: Array,
      default: () => []
    },
    addressRiskySources: {
      type: Array,
      default: () => []
    },
    addressKnownSources: {
      type: Array,
      default: () => []
    },
    addressUnknownSources: {
      type: Array,
      default: () => []
    },
    messages: {
      type: Array,
      default: () => []
    },
    tokensList: {
      type: Array,
      default: () => []
    },
    addressDataLoading: {
      type: Boolean,
      default: false
    },
    calculationLoading: {
      type: Boolean,
      default: false
    },
    percent: {
      type: [Number, String],
      default: 0
    },
    totalFunds: {
      type: [Number, String],
      default: 0
    },
    totalAmount: {
      type: [Number, String],
      default: 0
    },
    address: {
      type: [String],
      default: ''
    },
    selectedToken: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      RISK_POINT: 55,
    }
  },
  computed: {
    ...mapState('analytics', ['coinData']),
    hasTxs() {
      return Boolean(this.addressData.txCount || this.allDataSource.length || this.allDataSourceByOwner.length)
    },
    sectionsComponent() {
      if (this.contractData.type == 'address') return EthSectionAddressInfo;
      if (this.contractData.type == 'contract') return EthSectionContractInfo;
      if (this.contractData.type == 'tokenContract') return EthSectionTokenContractInfo;
      return EthSectionAddressInfo
    }
  },
  methods: {
    formatDate,
    toComaSeparate,
    formatFunds,
    trancateString,
    capitalizeFirstLetter,
    formatBtcAmount,
    findColorByTypeScore,
    ownerLabelFormatter,
    hex2rgba,
    selectToken(token) {
      this.selectedToken = token
      this.$emit('set-token', this.selectedToken)
    },
    explore() {
      const { href } = this.$router.resolve({ name: 'analytics', query: { address: this.address, type: this.coinData.key } })
      window.open(href, '_blank')
    },
    formatShare(share) {
      const formatted = (share).toFixed(2)
      return formatted === '0.00' ? '< 0.01%' : formatted + '%'
    },
  },
}
</script>

<style>
.wallet-tx-wrap {
  display: grid;
  grid-template-columns: 2fr 2fr;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
@media screen and (min-width: 1450px) {
  .wrapper {
    grid-template-columns: repeat(4, 2fr);
  }
}
</style>
