<template>
  <div>
    <div
      id="report"
    >
      <div class="flex">
        <div class="flex-2 mr-4">
          <Section class="mb-4">
            <template #title>
              <div>
                Transaction Information
              </div>
            </template>

            <template #body>
              <div
                v-if="!txData.tx_hash && !txDataLoading"
                class="mb-4 pa-2"
              >
                There are not transaction for this hash
              </div>
              <div class="pa-2">
                <InfoBlock
                  class="mb-4"
                  flex-second-column-two
                  inline-view
                  label="Tx hash"
                  :loading="txDataLoading"
                  :value="txData.tx_hash"
                  word-break-value
                />
                <InfoBlock
                  class="mb-4"
                  flex-second-column-two
                  inline-view
                  label="Total amount"
                  :loading="txDataLoading"
                  :value="toComaSeparate(String(`${formatBtcAmount(totalAmount.amount / (Math.pow(10, Number.isInteger(totalAmount.decimals) ? totalAmount.decimals : coinData.decimals)), true, coinData.key, totalAmount.symbol)}`))"
                />
                <InfoBlock
                  class="mb-4"
                  flex-second-column-two
                  inline-view
                  label="Block"
                  :loading="txDataLoading"
                  :value="txData
                    && txData.blockHeight
                    && toComaSeparate(String(txData.blockHeight))"
                />
                <InfoBlock
                  class="mb-4"
                  flex-second-column-two
                  inline-view
                  label="Timestamp"
                  :loading="txDataLoading"
                  :value="txData
                    && txData.timestamp
                    && formatDate(txData.timestamp * 1000, 'dd.MM.yyyy HH:mm')"
                />
                <div v-if="txData.contractsListData && txData.contractsListData.length">
                  <div class="sidebar__analytics-label">
                    Tokens
                  </div>
                  <div
                    class="flex flex-wrap"
                  >
                    <span
                      v-for="contract in txData.contractsListData"
                      :key="contract.address"
                      class="flex align-center mr-2 mb-1 token-chips"
                      :class="{'token-chips--selected': selectedToken.address === contract.address}"
                      @click="selectToken(contract)"
                    >
                      <GlImg
                        v-if="contract.icon"
                        class="mr-1"
                        :src-val="contract.icon"
                      />
                      {{ contract.name }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </Section>
        </div>
        <div class="flex-1 report-risk-score-wrapper">
          <Section class="mb-4">
            <template #title>
              <div>
                Risk Score
              </div>
            </template>

            <template #body>
              <div class="pa-3">
                <GlProgressBarChart
                  :calculation-loading="calculationLoading"
                  :total-funds="totalFunds"
                />
                <div
                  v-if="txRiskySources.length > 0"
                  class="mt-4 mb-4"
                >
                  <strong>{{ formatShare(percent*100) }}</strong> of funds comes from risky sources
                </div>
                <div
                  v-else
                  class="mt-4 mb-4"
                >
                  No risky sources were found
                </div>
                <div
                  v-if="!calculationLoading"
                  class="mb-5"
                >
                  <p
                    v-if="percent * 100 > 0 || addressAreUnidentified || messages.length > 0"
                    class="sidebar__analytics-label"
                  >
                    AML RISK DETECTED
                  </p>
                  <StatusBlock
                    v-if="percent * 100 > 0"
                    class="mb-2"
                    :label="`${formatShare(percent * 100)} of funds in this transaction are from risky sources`"
                  />
                  <StatusBlock
                    v-if="addressAreUnidentified"
                    class="mb-2"
                    label="More than 75% of sources for this transaction are unidentified"
                  />
                  <StatusBlock
                    v-for="(msg, index) in messages"
                    :key="index"
                    class="mb-2"
                    :label="msg"
                  />
                </div>
                <RiskScoreDisclaimer />
                <button
                  class="gl-button gl-button--dark gl-button--padder gl-button--full mt-3"
                  :disabled="txDataLoading"
                  @click="explore(txData.tx_hash)"
                >
                  Explore graph
                </button>
              </div>
            </template>
          </Section>
        </div>
      </div>

      <Section
        v-if="allDataSource.length > 0 && allDataSourceByOwner.length > 0 || calculationLoading"
        class="mb-4"
      >
        <template #title>
          <div>
            Sources of Funds
          </div>
        </template>

        <template #body>
          <div class="py-14px pl-2 grey-text-6-e mb-3">
            Counterparties from whom requested address has received funds
          </div>
          <div class="flex mb-4 flex-wrap pl-2 pr-2">
            <div class="flex-1">
              <PieDataList
                :data="allDataSource"
                :loading="calculationLoading"
                title="By Type"
                track-by-label="funds.type"
                track-by-label-support="funds.name"
              />
            </div>
            <div class="flex-1">
              <PieDataList
                :data="allDataSourceByOwner"
                :loading="calculationLoading"
                title="By Owner"
                track-by-label="owner"
              />
            </div>
          </div>
        </template>
      </Section>
    </div>
    <div>
      <SwapTable
        v-if="swapSources.length > 0 && !txDataLoading"
        class="mb-5"
        :data="swapSources"
        :has-only-one-source="hasOnlyOneSource"
        :loading="txDataLoading"
      />
      <txRiskyTable
        v-if="txRiskySources.length > 0 && !txDataLoading"
        class="mb-5"
        :data="txRiskySources"
        :has-only-one-source="hasOnlyOneSource"
        :loading="txDataLoading"
      />
      <txUnknownTable
        v-if="txUnknownSources.length > 0 && !txDataLoading"
        class="mb-5"
        :data="txUnknownSources"
        :has-only-one-source="hasOnlyOneSource"
        :loading="txDataLoading"
      />
      <txKnownTable
        v-if="txKnownSources.length > 0 && !txDataLoading"
        :data="txKnownSources"
        :has-only-one-source="hasOnlyOneSource"
        :loading="txDataLoading"
      />
    </div>
  </div>
</template>

<script>
// Components
import GlImg from "@/components/gl-img.vue";
import InfoBlock from '@/components/gl-info-block'
import Section from '@/components/gl-section'
import PieDataList from'../components/PieDataList'
import SwapTable from "@/pages/report/components/SwapTable";
import StatusBlock from "@/pages/report/components/StatusBlock";
import txRiskyTable from "@/pages/report/components/TxRiskyTable";
import txKnownTable from "@/pages/report/components/TxKnownTable";
import txUnknownTable from "@/pages/report/components/TxUnknownTable";
import GlProgressBarChart from "@/components/charts/gl-progress-bar-chart";
import RiskScoreDisclaimer from "@/pages/report/components/RiskScoreDisclaimer";
// Utils
import {formatDate} from "@/utils/format-date";
import {toComaSeparate} from "@/utils/formatNumber";
import {formatBtcAmount} from "@/utils/format-btc-amount";
import {capitalizeFirstLetter} from "@/utils/text-formatter";
import {findColorByTypeScore, hex2rgba} from "@/utils/cytoskape-ui-rules";
import { formatFunds } from "@/utils/report-data-formatter";
// Vuex
import {mapState} from "vuex";

export default {
  components: {
    GlImg,
    InfoBlock,
    Section,
    SwapTable,
    StatusBlock,
    PieDataList,
    txRiskyTable,
    txKnownTable,
    txUnknownTable,
    RiskScoreDisclaimer,
    GlProgressBarChart
  },
  props: {
    hasOnlyOneSource: {
      type: Boolean,
      default: false
    },
    txData: {
      type: Object,
      default: () => ({})
    },
    selectedToken: {
      type: Object,
      default: () => ({})
    },
    swapSources: {
      type: Array,
      default: () => []
    },
    allDataSource: {
      type: Array,
      default: () => []
    },
    txRiskySources: {
      type: Array,
      default: () => []
    },
    txKnownSources: {
      type: Array,
      default: () => []
    },
    txUnknownSources: {
      type: Array,
      default: () => []
    },
    allDataSourceByOwner: {
      type: Array,
      default: () => []
    },
    messages: {
      type: Array,
      default: () => []
    },
    txDataLoading: {
      type: Boolean,
      default: false
    },
    calculationLoading: {
      type: Boolean,
      default: false
    },
    percent: {
      type: [Number, String],
      default: 0
    },
    totalFunds: {
      type: [Number, String],
      default: 0
    },
    totalAmount: {
      type: [Number, String, Object],
      default: 0
    },
    symbol: {
      type: [String],
      default: ''
    },
  },
  data() {
    return {
      RISK_POINT: 55
    }
  },
  computed: {
    ...mapState('analytics', ['coinType', 'coinData']),
    addressIsOwnerByHightRisk() {
      return (this.addressData.tags && this.addressData.tags.find(tag => tag.score >= this.RISK_POINT))
          || (this.addressData.clusterData.tags && this.addressData.clusterData.tags.find(tag => tag.score >= this.RISK_POINT))
          || this.addressData.type && this.addressData.type.score >= this.RISK_POINT
          || (this.addressData.clusterData.type && this.addressData.clusterData.type.score >= this.RISK_POINT)
    },
    hasDirectlyMixing() {
      return (this.addressData.type && this.addressData.type.name === 'mixing')
          || (this.addressData.clusterData.type && this.addressData.clusterData.type.name === 'mixing')
          || (this.addressData.tags && this.addressData.tags.find(tag => tag.name === 'coin join participant'))
          || (this.addressData.clusterData.tags && this.addressData.clusterData.tags.find(tag => tag.name === 'coin join participant'))
    },
    hasTagMoreRiskPoint() {
      return (this.addressData.tags && this.addressData.tags.find(tag => tag.score >= this.RISK_POINT))
          || (this.addressData.clusterData.tags && this.addressData.clusterData.tags.find(tag => tag.score >= this.RISK_POINT))
    },
    addressAreUnidentified() {
      const sum = this.txUnknownSources.reduce((acc, { share }) => acc + share, 0)

      return sum * 100 >= 75
    },
  },
  methods: {
    formatDate,
    toComaSeparate,
    formatFunds,
    capitalizeFirstLetter,
    formatBtcAmount,
    findColorByTypeScore,
    hex2rgba,
    selectToken(contract) {
      this.$emit('set-token', this.selectedToken.address === contract.address
        ? {}
        : contract )
    },
    explore(tx) {
      const { href } = this.$router.resolve({ name: 'analytics', query: { tx, type: this.coinType } })
      window.open(href, '_blank')
    },
    formatShare(share) {
      const formatted = (share).toFixed(2)
      return formatted === '0.00' ? '< 0.01%' : formatted + '%'
    },
  },
}
</script>

<style>
.wallet-tx-wrap {
  display: grid;
  grid-template-columns: 2fr 2fr;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
@media screen and (min-width: 1450px) {
  .wrapper {
    grid-template-columns: repeat(4, 2fr);
  }
}
</style>
